
export const personal_data = {

    User_Name_Surname : "Luca Conti",
    GitHub_name : "lou6891",
    Linkedin_url : "https://www.linkedin.com/in/lucaconti1999/",
    Website_url : "https://linktr.ee/lucaconti",

    About_Page : {
        Professional_summary :
            "I am a highly skilled and motivated computer science professional with a passion for delivering measurable results through \n" +
            "the application of my coding and problem-solving skills. With a background in both business and computer science, I am able \n" +
            "to approach challenges from a well-rounded perspective and find creative solutions to complex problems. In my past \n" +
            "experiences, I have demonstrated my skills to drive digital transformation, by implementing a digital system for purchase \n" +
            "requests and building a website using NodeJS and ReactJS. I am always seeking to take on new challenges.",

        Education :[
            {
                school_name : "IE School of Science and Technology",
                date_start : "September 2022",
                date_end : "July 2023",
                program : "Master in Computer Science and Business Technology",
                locations : [
                    "Madrid, ES",
                ],
                information : [
                    "Used Python for machine learning and data analytics",
                    "Knowledge about cloud systems, infrastructure and development",
                ],

            },

            {
                school_name : "IE Business School",
                date_start : "January 2022",
                date_end : "July 2023",
                program : "Master in Management",
                locations : [
                    "Madrid, ES",
                ],
                information : [
                    "Dean's list award",
                    "Awarded membership into Beta Gamma Sigma",
                    "Graduated with 3.8 GPA",
                ],

            },

            {
                school_name : "ESCP European Business School",
                date_start : "September 2022",
                date_end : "July 2023",
                program : "Bachelor in Management",
                locations : [
                    "London, UK",
                    "Turin, IT",
                    "Berlin, DE"
                ],
                information : [
                    "Dean's list award for two consecutive years",
                    "Graduated with High Honors and 3.8 GPA",
                ],

            },

        ],

        Professional_experiences :[
            {
                company_name : "Centro Style S.P.A",
                company_information  : "Family company that serve the need of optical show in more than 80 countries since 40 years",
                date_start : "September 2022",
                date_end : "July 2023",
                locations : [
                    "Varese, IT"
                ],
                position_name : "monitoring and Controlling",
                position_information : [
                    "Developed a model for the order workflow for goods not for resale. " +
                    "Automated and streamlined the process from a paper one to a excel model, using the SQL language and structured a plan for the further implementation of the process in BI",
                    "Participated in the editing and analysis of business units P&L, forecasting P&L and budget, with the Financial team we \n" +
                    "created a model for the forecast of the P&L, including sales and costs budgets of different business Units",
                    "Curated the monthly reporting of key financial and commercial indicators to the department managers",
                ],

            },

        ],

        IT_skills : [
            "NodeJs",
            "ReactJs",
            "Python",
            "Dockerfile",
            "CSS",
            "HTML",
            "AWS",
            "Google Cloud",
        ],

        // Write all the languges in english, key = Language name, value = level of the language
        Languages : [
            // Language name  : level from 0 to 5, 0 being never spoken, 5 being native
            ["Italian" , 5, "Native"],
            ["English" , 4, "Fluent"],
            ["Spanish" , 2, "Limited Working proficiency"],
        ],


    },
}